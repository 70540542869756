@import "../../styles/breakpoints.scss";

.portfolio-category-image-view {
  background: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  user-select: none;

  .close-button,
  .previous-image-button,
  .next-image-button {
    position: fixed;
    margin: 0;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: white;
    }
  }

  .close-button {
    top: 0.5em;
    right: 0.5em;
    height: 32px;
    width: 32px;
  }

  .previous-image-button,
  .next-image-button {
    top: calc(50vh - 24px);
    width: 48px;
    height: 48px;
  }

  .previous-image-button {
    left: 5vw;
  }

  .next-image-button {
    right: 5vw;
  }

  .image-container {
    height: 100%;
    padding: 2.5em 2em;

    @include mobile {
      padding: 0;
    }

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      background: black;
    }
  }
}
