@import "../styles/theme.scss";
@import "../styles/breakpoints.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $header-height;
  background: $primary-color;
  padding: 0 24px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 9999;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);

  .company-logo {
    display: flex;
    align-items: center;

    a img {
      height: $header-height;
    }

    .menu-button {
      display: none;

      @include mobile {
        display: block;
        background: url(../assets/baseline-menu-24px.svg);
        width: 24px;
        height: 24px;
        transition: transform 0.5s;
        margin-right: 24px;

        &:active {
          transform: scale(0.8);
        }
      }
    }
  }

  .links {
    height: 100%;

    a {
      height: $header-height;
      line-height: $header-height;

      &:hover {
        color: rgba(255, 255, 255, 0.95);
      }
    }

    @include mobile {
      display: none;
    }
  }

  .drawer {
    position: fixed;
    top: $header-height;
    left: 0;
    height: 0;
    transition: height 0.4s;
    overflow: hidden;
    background: $primary-color;
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding-left: 24px;

    &.open {
      @include mobile {
        height: calc(100vh - #{$header-height});
      }
    }

    a {
      font-size: 1.2em;
      line-height: 2em;

      &:first-child {
        margin-top: 1em;
      }
    }
  }
}
