@import "../../styles/breakpoints.scss";

.contact-page {
  flex: 1;
  display: flex;
  align-items: center;

  .content {
    flex-grow: 1;
    display: flex;
    height: 80%;
    padding: 2em;
    margin: 0 auto;
    max-width: 1400px;

    .google-map-wrapper {
      height: 100%;
      flex: 0.7 1 0;
      margin-right: 3em;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }

    .company-contact {
      flex: 0.3 1 0;
      font-size: 16px;
      line-height: 1.5;

      .name {
        color: rgba(0, 0, 0, 0.9);
      }

      .nip,
      .address,
      .phone {
        margin: 0 0;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  @include mobile {
    align-items: flex-start;

    .content {
      height: 100%;
      flex-direction: column-reverse;
      justify-content: flex-end;
      padding: 1.2em;

      .google-map-wrapper {
        margin-right: 0;
        flex-grow: 1;
      }

      .company-contact {
        margin-bottom: 2em;
        flex-grow: 0;

        .name {
          font-size: 18px;
          margin-top: 0;
        }

        .nip,
        .address,
        .phone {
          font-size: 15px;
        }
      }
    }
  }
}
