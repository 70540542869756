@import "../styles/theme.scss";
@import "../styles/breakpoints.scss";

.footer {
  background: $primary-color;
  padding: 1rem 0;
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.2);
  position: sticky;
  bottom: 0;
  z-index: 999;

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 1em;
    max-width: $footer-content-max-width;

    @include mobile {
      align-items: flex-start;
    }

    .social-media-links {
      display: flex;

      .social-icon {
        width: 42px !important;
        height: 42px !important;
      }

      @include mobile {
        flex-direction: column;
        align-items: center;

        .first-row {
          margin-bottom: 8px;
        }

        .social-icon {
          width: 32px !important;
          height: 32px !important;
        }
      }

      .social-icon {
        position: static !important;
        cursor: pointer;

        .social-container {
          position: static !important;

          .social-svg {
            position: static !important;
          }
        }
      }
    }

    .company-contact {
      color: rgba(255, 255, 255, 0.7);
      font-size: 0.75rem;
      display: flex;
      flex-direction: column;
      line-height: 1.5;
      margin-right: 8px;
    }
  }
}
