@import "../../styles/breakpoints.scss";

.portfolio-category-page {
  flex: 1;
  padding: 2em;

  .content {
    .category-name {
      display: flex;
      align-items: center;
      margin: 0 0 1em;

      .go-back-to-categories-button {
        width: 1em;
        height: 1em;

        cursor: pointer;
        transition: transform 0.3s ease-in-out;

        &:hover {
          transform: scale(1.05);
        }

        &:active {
          transform: scale(0.95);
        }
      }
    }

    .images-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: 400px;
      grid-column-gap: 2em;
      grid-row-gap: 2em;

      @include tablet {
        grid-template-columns: 1fr 1fr;
      }

      @include mobile {
        grid-template-columns: 1fr;
      }

      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.2), -2px -2px 15px 0 rgba(0, 0, 0, 0.2);
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: scale(1.02);
        }

        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
}
