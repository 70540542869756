@import "../../styles/breakpoints.scss";

.portfolio-page {
  flex: 1;
  padding: 2em;

  @include mobile {
    padding: 1.2em;
  }

  .content {
    .categories-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: 400px;
      grid-column-gap: 1em;
      grid-row-gap: 1em;

      @include tablet {
        grid-template-columns: 1fr 1fr;
      }

      @include mobile {
        grid-template-columns: 1fr;
      }

      .category-card {
        height: 100%;
        display: grid;
        grid-template-rows: 1fr auto;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        border-radius: 4px;
        overflow: hidden;

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          flex-grow: 1;
          transition: opacity 0.2s ease-in-out, transform 0.5s ease-in-out;
        }

        .name {
          text-align: center;
          padding: 1em;
          margin: 0;
          color: rgba(0, 0, 0, 0.9);
          z-index: 2;
          background: white;
        }

        &:hover {
          opacity: 0.8;

          .image {
            transform: scale(1.2);
          }
        }

        &:active {
          opacity: 1;

          .image {
            transform: scale(1);
          }
        }
      }
    }
  }
}
