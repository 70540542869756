$primary-color: rgb(41, 47, 56);
$background-color: rgb(245, 245, 245);
$header-height: 48px;
$footer-content-max-width: 1400px;

a,
button {
  cursor: pointer;
}

button {
  outline: none;
  border: none;
}

a {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  margin: 0 8px;
  transition: color 0.2s;
  display: inline-block;
}
