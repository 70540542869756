$number-of-images: 3;
$image-transition-duration: 1.5s;
$image-duration: 4s;
$frame-duration: $image-duration + $image-transition-duration;
$animation-duration: $number-of-images * ($image-transition-duration + $image-duration);
$image-step: ($image-duration / ($image-duration + $image-transition-duration)) * 100% / $number-of-images;
$image-transition-step: ($image-transition-duration / ($image-duration + $image-transition-duration)) * 100% /
  $number-of-images;
$frame-step: $image-step + $image-transition-step;

@keyframes slideshow-animation {
  @for $i from 0 through $number-of-images {
    #{$i * $frame-step} {
      transform: translateX(#{$i * (-100% / ($number-of-images + 1))});
    }

    #{$i * $frame-step + $image-step} {
      transform: translateX(#{$i * (-100% / ($number-of-images + 1))});
    }
  }
}

@for $i from 0 through $number-of-images - 1 {
  @keyframes scroll-animation-#{$i + 1} {
    #{$frame-step * $i} {
      background-position: 20% center;
    }

    #{$frame-step * ($i + 1)} {
      background-position: 80% center;
    }
  }
}

.slider {
  height: 100%;
  overflow: hidden;

  .images {
    width: #{100% * ($number-of-images + 1)};
    height: 100%;
    animation: slideshow-animation $animation-duration linear infinite;
    display: flex;
    will-change: tranform;

    div {
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 20% center;
    }

    @for $i from 1 through $number-of-images {
      div:nth-child(#{$i}) {
        animation: scroll-animation-#{$i} #{$animation-duration} linear infinite backwards;
      }
    }
  }
}
