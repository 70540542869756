@import "../../styles/breakpoints.scss";

.company-page {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0 1.5em;

  div {
    display: flex;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    align-items: center;

    .text,
    .image {
      flex: 1 1 0;
    }

    .text {
      margin: 0 3em 0 0;
      text-align: justify;
    }

    .image {
      width: 50%;
      object-fit: cover;
    }

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      padding: 1.2em;

      .text {
        margin: 0 0 1.5em;
      }

      .image {
        width: 100%;
      }
    }
  }
}
