@mixin mobile {
  @media screen and (max-width: 700px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 1000px) {
    @content;
  }
}
